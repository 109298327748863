import { eachDayOfInterval, format, isAfter, isEqual } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export type Weekday = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

export const WEEKDAY_LABELS: Record<Weekday, string> = {
  monday: "Mandag",
  tuesday: "Tirsdag",
  wednesday: "Onsdag",
  thursday: "Torsdag",
  friday: "Fredag",
  saturday: "Lørdag",
  sunday: "Søndag",
};

export const WEEKDAYS = Object.keys(WEEKDAY_LABELS) as Weekday[];

export const isWeekday = (potentialWeekday: string): potentialWeekday is Weekday =>
  Object.keys(WEEKDAY_LABELS).includes(potentialWeekday);

const NORWEGIAN_TIMEZONE = "Europe/Oslo";

export const norwegianDateToUTC = (date: Date | string) => zonedTimeToUtc(date, NORWEGIAN_TIMEZONE);

export const UTCDateToNorwegian = (date: Date | string) => utcToZonedTime(date, NORWEGIAN_TIMEZONE);

export const getNorwegianWeekday = (date: Date | string): Weekday => {
  const weekday = format(UTCDateToNorwegian(date), "iiii").toLowerCase();
  if (!isWeekday(weekday)) throw new Error("Could not get Norwegian weekday");
  return weekday;
};

export const getWeekdayIndex = (weekday: Weekday): number => WEEKDAYS.indexOf(weekday);

export const createConstantWeekObject = <T extends unknown>(dayValue: T): Record<Weekday, T> =>
  WEEKDAYS.reduce(
    (acc, weekday) => ({
      ...acc,
      [weekday]: dayValue,
    }),
    {} as Record<Weekday, T>
  );

export type Period = { from: string; isInterval?: boolean; to?: string };

export const getPeriodEnd = <T extends Period>({ from, isInterval, to }: T) => (isInterval ? to : from) as string;

export const isValidPeriod = <T extends Partial<Period>>(potentialPeriod: T): potentialPeriod is T & Period => {
  const { from, isInterval, to } = potentialPeriod;
  if (!from || (isInterval && !to)) return false;

  const fromDate = new Date(from);
  const toDate = new Date(getPeriodEnd(potentialPeriod as Period));

  return isEqual(fromDate, toDate) || isAfter(toDate, fromDate);
};

export const getPeriodAsDates = ({ from, isInterval, to }: Period): Date[] => {
  const fromDate = new Date(from!);
  return isInterval ? eachDayOfInterval({ start: fromDate, end: new Date(to!) }) : [fromDate];
};

export const SIMPLE_ISO_DATE_FORMAT = "yyyy-MM-dd";
