import { RichTextBlock } from "../generatedTypes/libryContentSanityTypes";

export type LanguageCode = "nb" | "nn" | "se" | "en";

export const isLanguageCode = (type: string): type is LanguageCode => ["nb", "nn", "se", "en"].includes(type);

export type FieldValue = boolean | string | RichTextBlock | undefined;

export type LocalizedField<T = FieldValue> = Partial<Record<LanguageCode, T>>;

const isEmpty = (obj: object) => Object.values(obj).length === 0;

const omit = (obj: object, omittedKey: string): object =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => omittedKey !== key));

export const isLocalizedField = (value: unknown) =>
  typeof value === "object" && value != null && Object.keys(omit(value, "_type")).every(isLanguageCode);

type Languages = Record<LanguageCode, { name: string }>;

export const LANGUAGES: Languages = {
  nb: { name: "Bokmål" },
  nn: { name: "Nynorsk" },
  se: { name: "Davvisámegiella" },
  en: { name: "English" },
};

// https://stackoverflow.com/a/55012175/1540547
export const LANGUAGE_CODES = Object.keys(LANGUAGES) as LanguageCode[];

export const DEFAULT_LANGUAGE_CODE: LanguageCode = "nb";

export const ts = <T = FieldValue>(
  localizedObject: LocalizedField<T> | undefined,
  languageCode: LanguageCode = DEFAULT_LANGUAGE_CODE
): T | undefined => {
  if (typeof localizedObject === "string") {
    console.error("Attempted to get localized text from a string:", localizedObject);
    return localizedObject;
  }
  if (!localizedObject || isEmpty(localizedObject)) return undefined;
  if (languageCode && languageCode in localizedObject) return localizedObject[languageCode];
  return Object.values(localizedObject)[0];
};
